<template>
  <div>
    <b-row>
      <b-col>
        <h1>
          Dashboard
          <b-button
            variant="transparant"
            v-b-tooltip.hover.bottom="`Layout resetten`"
            @click="resetLayout()"
            class="mr-2"
          >
            <i class="far fa-rotate mr-0" />
          </b-button>
          <b-button
            class="btn-hover-rotate btn-pill"
            id="filter-button"
            variant="transparant"
            href="#"
            tabindex="0"
          >
            <i class="far fa-eyes mr-0" />
            <b-badge v-if="removed_layout.length" pill variant="secondary">
              {{ removed_layout.length }}
            </b-badge>
          </b-button>
        </h1>
        <h2 class="sub-page-title">Welkom {{ auth.first_name }}</h2>
        <b-popover
          custom-class="removed-layout-popover"
          target="filter-button"
          triggers="focus"
          placement="bottom"
        >
          <p><b>Verborgen items</b></p>
          <p v-if="!removed_layout.length">Geen verborgen items</p>
          <p
            class="removed-layout-item"
            v-for="item in removed_layout"
            :key="item.i"
          >
            <span class="add-layout-item" @click="reAddLayoutItem(item)">
              <i class="far fa-plus" />
            </span>

            <template v-if="item.i === 'best-sold-products'">
              Best verkochte producten
            </template>
            <template v-if="item.i === 'purchasing-costs'"> Inkoop </template>
            <template v-if="item.i === 'revenue'"> Omzet </template>
            <template v-if="item.i === 'invoiced-revenue'">
              Gefactureerde omzet
            </template>
            <template v-if="item.i === 'order-count'"> Bestellingen </template>
            <template v-if="item.i === 'samples-order-count'">
              Proefmonster bestellingen
            </template>
            <template v-if="item.i === 'best-sold-samples'">
              Best verkochte proefmonster
            </template>
            <template v-if="item.i === 'customers'">
              Klanten met hoogste omzet
            </template>
            <template v-if="item.i === 'agents'">
              Omzet per vertegenwoordiger
            </template>
            <template v-if="item.i === 'catalog-requests'">
              Catalogus aanvragen
            </template>
            <template v-if="item.i === 'best-sold-collections'">
              Omzet per collectie
            </template>
            <template v-if="item.i === 'tasklist'"> Takenlijst </template>
          </p>
        </b-popover>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card bodyClass="overflow-visible">
          <b-row>
            <b-col md="12">
              <b-form-group
                class="mb-0"
                label="Periode"
                description="Kies een periode waarvoor je statistieken wilt zien"
              >
                <b-select v-model="filters.period" @change="filter()">
                  <b-select-option :value="null"> Alles </b-select-option>
                  <b-select-option value="today"> Vandaag </b-select-option>
                  <b-select-option value="yesterday">
                    Gisteren
                  </b-select-option>
                  <b-select-option value="last_week">
                    Afgelopen 7 dagen
                  </b-select-option>
                  <b-select-option value="last_thirty_days">
                    Afgelopen 30 dagen
                  </b-select-option>
                  <b-select-option value="other">
                    Anders, namelijk..
                  </b-select-option>
                </b-select>
              </b-form-group>
            </b-col>

            <b-col md="12" v-if="filters.period === 'other'">
              <b-form-group
                class="mb-0"
                label="Jaargang"
                description="Kies op basis van welk jaar je statistieken wilt zien"
              >
                <b-select v-model="filters.year" @change="filter()">
                  <b-select-option :value="null" selected>
                    Alle jaren
                  </b-select-option>
                  <b-select-option
                    v-for="(year, index) in options.years"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </b-select-option>
                </b-select>
              </b-form-group>
            </b-col>

            <b-col md="12" v-if="filters.period === 'other'">
              <b-form-group
                label="Maand"
                description="Kies op basis van welke maand je statistieken wilt zien"
                class="mb-0"
              >
                <b-button
                  variant="input"
                  block
                  @click="selectExpanded = !selectExpanded"
                >
                  {{ buttonText }}
                  <i class="fas fa-sort" />
                </b-button>

                <template v-if="selectExpanded">
                  <div class="v-select search-select">
                    <div class="v-dropdown-container pt-0">
                      <b-form-group class="mb-0">
                        <b-form-checkbox-group
                          v-model="filters.month"
                          stacked
                          @change="filter()"
                        >
                          <b-form-checkbox
                            v-for="(month, index) in options.months"
                            :key="index"
                            :value="month"
                            class="m-2"
                          >
                            {{ new Date(2000, --month, 1) | date('LLLL') }}
                          </b-form-checkbox>
                        </b-form-checkbox-group>
                      </b-form-group>
                    </div>
                  </div>
                </template>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <grid-layout
      :layout="layout"
      :col-num="12"
      :row-height="10"
      :max-rows="1000"
      :is-draggable="true"
      :is-resizable="true"
      :vertical-compact="true"
      :margin="[20, 20]"
      @layout-updated="saveLayout"
    >
      <grid-item
        v-for="item in layout"
        :minW="item.minW"
        :minH="item.minH"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
      >
        <div class="controls">
          <span class="remove" @click="removeItem(item.i)">
            <i class="fas fa-eye-slash" />
          </span>
          <span ref="handle" class="vue-resizable-handle">
            <i class="far fa-up-right-and-down-left-from-center" />
          </span>
        </div>

        <best-sold-products
          v-if="item.i === 'best-sold-products'"
          :products="products"
          :products_meta="products_meta"
          :loading="loading.products"
        />

        <ordered-revenue
          v-if="item.i === 'revenue'"
          :purchase-total="ordered_revenue.current_period.purchase_total"
          :old-purchase-total="ordered_revenue.previous_period.purchase_total"
          :value="ordered_revenue.current_period.total_sales"
          :old-value="ordered_revenue.previous_period.total_sales"
          :loading="loading.ordered_revenue"
        />

        <invoiced-revenue
          v-if="item.i === 'invoiced-revenue'"
          :purchase-total="invoiced_revenue.current_period.purchase_total"
          :old-purchase-total="invoiced_revenue.previous_period.purchase_total"
          :value="invoiced_revenue.current_period.total_sales"
          :old-value="invoiced_revenue.previous_period.total_sales"
          :loading="loading.invoiced_revenue"
        />

        <stat-block
          v-if="item.i === 'order-count'"
          :title="`Bestellingen`"
          :currency="false"
          :value="orders.current_period.total_orders"
          :old-value="orders.previous_period.total_orders"
          :loading="loading.orders"
        />

        <stat-block
          v-if="item.i === 'samples-order-count'"
          :title="`Proefmonster bestellingen`"
          :currency="false"
          :value="samples_orders.current_period.total_orders"
          :old-value="samples_orders.previous_period.total_orders"
          :loading="loading.samples_orders"
        />

        <best-sold-samples
          v-if="item.i === 'best-sold-samples'"
          :products="samples"
          :products_meta="samples_meta"
          :loading="loading.samples"
        />

        <customers
          v-if="item.i === 'customers'"
          :customers="customers"
          :meta="customers_meta"
          :loading="loading.customers"
        />

        <agents
          v-if="item.i === 'agents'"
          :data="agents"
          :meta="agents_meta"
          :loading="loading.agents"
        />

        <stat-block
          v-if="item.i === 'catalog-requests'"
          :title="`Catalogus aanvragen`"
          :currency="false"
          :value="catalog_requests.current_period.total_requests"
          :old-value="catalog_requests.previous_period.total_requests"
          :loading="loading.catalog_requests"
        />

        <best-sold-collections
          v-if="item.i === 'best-sold-collections'"
          :data="best_sold_collections"
          :meta="best_sold_collections_meta"
          :loading="loading.best_sold_collections"
        />

        <tasklist v-if="item.i === 'tasklist'" />

        <revenue-by-delivery-country
          v-if="item.i === 'revenue-by-delivery-country'"
          :data="revenue_by_delivery_country"
          :loading="loading.revenue_by_delivery_country"
        />
      </grid-item>
    </grid-layout>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { GridLayout, GridItem } from 'vue-grid-layout';
import BestSoldProducts from '@/components/Stats/BestSoldProducts';
import BestSoldSamples from '@/components/Stats/BestSoldSamples';
import Customers from '@/components/Stats/Customers';
import Agents from '@/components/Stats/Agents';
import Tasklist from '@/components/Stats/Tasklist';
import BestSoldCollections from '@/components/Stats/BestSoldCollections';
import StatBlock from '@/components/Stats/StatBlock';
import InvoicedRevenue from '@/components/Stats/InvoicedRevenue';
import OrderedRevenue from '@/components/Stats/OrderedRevenue';
import RevenueByDeliveryCountry from '@/components/Stats/RevenueByDeliveryCountry';

export default {
  components: {
    GridLayout,
    GridItem,
    BestSoldProducts,
    BestSoldSamples,
    Customers,
    Agents,
    Tasklist,
    BestSoldCollections,
    StatBlock,
    InvoicedRevenue,
    OrderedRevenue,
    RevenueByDeliveryCountry,
  },

  data() {
    return {
      loading: {
        orders: false,
        samples_orders: false,
        products: false,
        samples: false,
        customers: false,
        agents: false,
        sales_report: false,
        catalog_requests: false,
        invoiced_revenue: false,
        ordered_revenue: false,
        best_sold_collections: false,
        revenue_by_delivery_country: false,
      },

      options: {
        years: [],
        months: [],
      },

      filters: {
        period: 'last_week',
        year: null,
        month: [],
      },

      selectExpanded: false,

      prices: {
        current_period: {},
        previous_period: {},
      },

      orders: {
        current_period: {},
        previous_period: {},
      },

      samples_orders: {
        current_period: {},
        previous_period: {},
      },

      catalog_requests: {
        current_period: {},
        previous_period: {},
      },

      ordered_revenue: {
        current_period: {},
        previous_period: {},
      },

      invoiced_revenue: {
        current_period: {},
        previous_period: {},
      },

      customers: [],
      customers_meta: {},

      agents: [],
      agents_meta: {},

      products: [],
      products_meta: {},

      samples: [],
      samples_meta: {},

      best_sold_collections: [],
      best_sold_collections_meta: {},

      revenue_by_delivery_country: [],
      revenue_by_delivery_country_meta: {},

      layout: [],
      removed_layout: [],
      default_layout: [
        { x: 0, y: 0, w: 6, h: 14, i: 'best-sold-products' },
        { x: 0, y: 14, w: 3, minW: 3, minH: 15, h: 15, i: 'revenue' },
        { x: 0, y: 17, w: 3, minW: 3, minH: 6, h: 6, i: 'order-count' },
        { x: 3, y: 29, w: 3, minW: 3, minH: 6, h: 6, i: 'samples-order-count' },
        { x: 0, y: 20, w: 3, minW: 3, minH: 6, h: 6, i: 'catalog-requests' },
        { x: 3, y: 14, w: 3, minW: 3, minH: 15, h: 15, i: 'invoiced-revenue' },
        { x: 0, y: 23, w: 6, h: 10, i: 'agents' },
        { x: 0, y: 33, w: 6, h: 10, minW: 5, i: 'tasklist' },
        { x: 6, y: 0, w: 6, h: 14, i: 'best-sold-samples' },
        { x: 6, y: 14, w: 6, h: 14, i: 'customers' },
        { x: 6, y: 28, w: 6, h: 10, i: 'best-sold-collections' },
        { x: 6, y: 38, w: 6, h: 10, minW: 5, i: 'revenue-by-delivery-country' },
      ],
    };
  },

  computed: {
    ...mapGetters({
      auth: 'auth/user',
    }),

    buttonText() {
      let text = 'Kies een maand';

      if (this.filters.month.length) {
        let months = [...this.filters.month];

        months.sort((a, b) => {
          if (a > b) return 1;
          if (a < b) return -1;
          return 0;
        });

        months = months.map((x) =>
          this.$options.filters.date(new Date(2000, --x, 1), 'LLLL')
        );
        text = months.join(', ');
      }

      return text;
    },
  },

  watch: {
    'filters.year'() {
      this.getMonths();
    },
  },

  methods: {
    filter() {
      this.getSalesReport();
      this.getOrdersReport();
      this.getCatalogRequests();
      this.getSamplesOrdersReport();
      this.getInvoicedRevenueReport();
      this.getOrderedRevenueReport();
      this.getRevenueByDeliveryCountry();
      this.getTopSoldSamples(true);
      this.getTopSoldProducts(true);
      this.getTopRevenueAgents(true);
      this.getTopSoldCollections(true);
      this.getTopRevenueCustomers(true);
    },

    removeItem: function (val) {
      const index = this.layout.map((item) => item.i).indexOf(val);
      this.removed_layout.push(this.layout.splice(index, 1)[0]);

      localStorage.setItem(
        'removed_layout',
        JSON.stringify(this.removed_layout)
      );
    },

    reAddLayoutItem(item) {
      const index = this.removed_layout.map((item) => item.i).indexOf(item);
      this.layout.push(this.removed_layout.splice(index, 1)[0]);
    },

    getMonths() {
      let params = {
        year: this.filters.year,
      };

      this.$http
        .get('stats/get-months', { params: params })
        .then((response) => {
          this.options.months = response.data;
        });
    },

    getYears() {
      this.$http.get('stats/get-years').then((response) => {
        this.options.years = response.data;
      });
    },

    getSamplesOrdersReport() {
      this.loading.samples_orders = true;

      let params = {
        year: this.filters.year,
        month: this.filters.month,
        period: this.filters.period,
        status: [
          this.$root.OrderStatus.SAMPLE_PROCESSING,
          this.$root.OrderStatus.SAMPLE_COMPLETED,
        ],
      };

      this.$http
        .get('stats/orders-report', { params: params })
        .then((response) => {
          this.samples_orders = response.data;
        })
        .then(() => {
          this.loading.samples_orders = false;
        });
    },

    getInvoicedRevenueReport() {
      this.loading.invoiced_revenue = true;

      let params = {
        year: this.filters.year,
        month: this.filters.month,
        period: this.filters.period,
      };

      this.$http
        .get('stats/invoiced-revenue-report', { params: params })
        .then((response) => {
          this.invoiced_revenue = response.data;
        })
        .then(() => {
          this.loading.invoiced_revenue = false;
        });
    },

    getOrderedRevenueReport() {
      this.loading.ordered_revenue = true;

      let params = {
        year: this.filters.year,
        month: this.filters.month,
        period: this.filters.period,
      };

      this.$http
        .get('stats/ordered-revenue-report', { params: params })
        .then(({ data }) => {
          this.ordered_revenue = data;
        })
        .then(() => {
          this.loading.ordered_revenue = false;
        });
    },
    getOrdersReport() {
      this.loading.orders = true;

      let params = {
        year: this.filters.year,
        month: this.filters.month,
        period: this.filters.period,
        status: [
          this.$root.OrderStatus.PROCESSING,
          this.$root.OrderStatus.SENT,
        ],
      };

      this.$http
        .get('stats/orders-report', { params: params })
        .then((response) => {
          this.orders = response.data;
        })
        .then(() => {
          this.loading.orders = false;
        });
    },

    getCatalogRequests() {
      this.loading.catalog_requests = true;

      let params = {
        year: this.filters.year,
        month: this.filters.month,
        period: this.filters.period,
      };

      this.$http
        .get('stats/catalog-requests', { params: params })
        .then((response) => {
          this.catalog_requests = response.data;
        })
        .then(() => {
          this.loading.catalog_requests = false;
        });
    },

    getSalesReport() {
      this.loading.sales_report = true;

      let params = {
        year: this.filters.year,
        month: this.filters.month,
        period: this.filters.period,
      };

      this.$http
        .get('stats/sales-report', { params: params })
        .then((response) => {
          this.prices = response.data;
        })
        .then(() => {
          this.loading.sales_report = false;
        });
    },

    getTopSoldProducts(reset = false) {
      this.loading.products = true;

      let page =
        typeof this.products_meta.current_page !== 'undefined' && !reset
          ? this.products_meta.current_page + 1
          : 1;

      let params = {
        year: this.filters.year,
        month: this.filters.month,
        period: this.filters.period,
        page: page,
      };

      this.$http
        .get('stats/top-sold-products', { params: params })
        .then((response) => {
          if (reset) {
            this.products = [];
            this.products_meta = {};
          }

          this.products = [...this.products, ...response.data.data];
          this.products_meta = response.data.meta;
        })
        .then(() => {
          this.loading.products = false;
        });
    },

    getTopSoldCollections(reset = false) {
      this.loading.best_sold_collections = true;

      let page =
        typeof this.best_sold_collections_meta.current_page !== 'undefined' &&
        !reset
          ? this.best_sold_collections_meta.current_page + 1
          : 1;

      let params = {
        year: this.filters.year,
        month: this.filters.month,
        period: this.filters.period,
        page: page,
      };

      this.$http
        .get('stats/top-sold-collections', { params: params })
        .then((response) => {
          if (reset) {
            this.best_sold_collections = [];
            this.best_sold_collections_meta = {};
          }

          this.best_sold_collections = [
            ...this.best_sold_collections,
            ...response.data.data,
          ];
          this.best_sold_collections_meta = response.data.meta;
        })
        .then(() => {
          this.loading.best_sold_collections = false;
        });
    },

    getTopSoldSamples(reset = false) {
      this.loading.samples = true;

      let page =
        typeof this.samples_meta.current_page !== 'undefined' && !reset
          ? this.samples_meta.current_page + 1
          : 1;

      let params = {
        year: this.filters.year,
        month: this.filters.month,
        period: this.filters.period,
        page: page,
      };

      this.$http
        .get('stats/top-sold-samples', { params: params })
        .then((response) => {
          if (reset) {
            this.samples = [];
            this.samples_meta = {};
          }

          this.samples = [...this.samples, ...response.data.data];
          this.samples_meta = response.data.meta;
        })
        .then(() => {
          this.loading.samples = false;
        });
    },

    getTopRevenueCustomers(reset = false) {
      this.loading.customers = true;

      let page =
        typeof this.customers_meta.current_page !== 'undefined' && !reset
          ? this.customers_meta.current_page + 1
          : 1;

      let params = {
        year: this.filters.year,
        month: this.filters.month,
        period: this.filters.period,
        page: page,
      };

      this.$http
        .get('stats/top-revenue-customers', { params: params })
        .then((response) => {
          if (reset) {
            this.customers = [];
            this.customers_meta = {};
          }

          this.customers = [...this.customers, ...response.data.data];
          this.customers_meta = response.data.meta;
        })
        .then(() => {
          this.loading.customers = false;
        });
    },

    getRevenueByDeliveryCountry() {
      this.loading.revenue_by_delivery_country = true;

      let params = {
        year: this.filters.year,
        month: this.filters.month,
        period: this.filters.period,
      };

      this.$http
        .get('stats/revenue-by-delivery-country', { params: params })
        .then(({ data }) => {
          this.revenue_by_delivery_country = data;
        })
        .then(() => {
          this.loading.revenue_by_delivery_country = false;
        });
    },

    getTopRevenueAgents(reset = false) {
      this.loading.agents = true;

      let page =
        typeof this.agents_meta.current_page !== 'undefined' && !reset
          ? this.agents_meta.current_page + 1
          : 1;

      let params = {
        year: this.filters.year,
        month: this.filters.month,
        period: this.filters.period,
        page: page,
      };

      this.$http
        .get('stats/top-revenue-agents', { params: params })
        .then((response) => {
          if (reset) {
            this.agents = [];
            this.agents_meta = {};
          }

          this.agents = [...this.agents, ...response.data.data];
          this.agents_meta = response.data.meta;
        })
        .then(() => {
          this.loading.agents = false;
        });
    },

    saveLayout(event) {
      localStorage.setItem('layout', JSON.stringify(event));
    },

    applyLayout() {
      this.layout = localStorage.getItem('layout')
        ? JSON.parse(localStorage.getItem('layout'))
        : JSON.parse(JSON.stringify(this.default_layout));

      this.removed_layout = localStorage.getItem('removed_layout')
        ? JSON.parse(localStorage.getItem('removed_layout'))
        : [];

      let unmmatched = this.default_layout.filter(
        (entry1) => !this.layout.some((entry2) => entry1.i === entry2.i)
      );

      unmmatched = unmmatched.filter(
        (entry1) => !this.removed_layout.some((entry2) => entry1.i === entry2.i)
      );

      this.removed_layout = [...this.removed_layout, ...unmmatched];
    },

    resetLayout() {
      localStorage.removeItem('layout');
      localStorage.removeItem('removed_layout');

      this.layout = JSON.parse(JSON.stringify(this.default_layout));
      this.removed_layout = [];
    },
  },

  mounted() {
    this.applyLayout();
    this.getYears();
    this.getMonths();
    this.getSalesReport();
    this.getOrdersReport();
    this.getTopSoldSamples();
    this.getCatalogRequests();
    this.getTopSoldProducts();
    this.getTopRevenueAgents();
    this.getTopSoldCollections();
    this.getTopRevenueCustomers();
    this.getSamplesOrdersReport();
    this.getInvoicedRevenueReport();
    this.getOrderedRevenueReport();
    this.getRevenueByDeliveryCountry();
  },
};
</script>
