<template>
  <div>
    <b-row>
      <b-col md="24">
        <Input
          label="Factuuremail"
          type="email"
          :error="errors.billing_email"
          v-model="customer.billing_email"
        />
      </b-col>
      <b-col md="24">
        <Input
          label="2e factuuremail"
          type="email"
          :error="errors.billing_email_second"
          v-model="customer.billing_email_second"
        />
      </b-col>
      <b-col md="24">
        <Input
          label="Factuur adres"
          type="text"
          :error="errors.billing_address"
          v-model="customer.billing_address"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="24">
        <Input
          label="Bedrijfsnaam"
          type="text"
          :error="errors.billing_company"
          v-model="customer.billing_company"
          id="customer-billing_company"
        />
      </b-col>
      <b-col md="24">
        <Input
          label="BTW Nummer"
          type="text"
          :error="errors.vat_number"
          v-model="customer.vat_number"
          id="customer-vat_number"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Betaalmethode"
          :disabled="auth.role.id !== $root.Role.ADMIN"
        >
          <b-form-select v-model="customer.payment_method">
            <b-select-option
              :value="$root.PaymentMethod.FOURTEEN_DAYS"
              selected
            >
              14 dagen
            </b-select-option>
            <b-select-option :value="$root.PaymentMethod.THIRTY_DAYS">
              30 dagen
            </b-select-option>
            <b-select-option :value="$root.PaymentMethod.DIRECT_DEBIT">
              Automatisch incasso
            </b-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-group label="BTW instellingen">
          <b-form-checkbox
            v-model="customer.is_foreign_customer"
            :value="true"
            :unchecked-value="false"
          >
            Dit is een buitenlandse klant (excl. BTW)
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Betaalopmerkingen"
          class="mb-1"
          description="Deze opmerking kan gebruikt worden als interne referentie"
        >
          <b-form-textarea
            v-model="customer.payment_comments"
            rows="8"
            placeholder="Plaats hier eventuele opmerkingen / bijzonderheden met betrekking tot betaling."
          />
        </b-form-group>
      </b-col>
    </b-row>

    <hr />

    <SentInvoicesTable
      :data="invoices"
      :meta="invoices_meta"
      :loading="loading"
      @updateSentInvoicesTable="getInvoices"
    />
  </div>
</template>

<script>
import SentInvoicesTable from '../_SentInvoicesTable';

import { mapGetters } from 'vuex';

export default {
  components: {
    SentInvoicesTable,
  },
  props: {
    customer: {
      type: Object,
      default: () => {
        return {};
      },
    },
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      invoices: [],
      invoices_meta: {
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      auth: 'auth/user',
    }),
  },
  methods: {
    getInvoices() {
      this.loading = true;

      let params = {
        customer: this.$route.params.id,
        page: this.$route.query.invoices_page
          ? this.$route.query.invoices_page
          : null,
        status: 2,
      };

      this.$http
        .get('invoices', { params: params })
        .then((response) => {
          this.invoices = response.data.data;
          this.invoices_meta = response.data.meta;
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getInvoices();
  },
};
</script>
