var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"secondary","bg-color":"#fff","opacity":"1","rounded":"sm"}},[_c('b-card',{staticClass:"card-stats",attrs:{"no-body":""}},[_c('h2',{staticClass:"stat-title stat-title-scroll"},[_vm._v("Omzet per land van levering")]),_c('b-card-body',[(_vm.data.length)?_vm._l((_vm.data),function(country,index){return _c('p',{key:index,staticClass:"my-1 stat-product"},[_c('b-badge',{staticClass:"mx-2 text-monospace",attrs:{"variant":"primary"}},[_vm._v(" € "+_vm._s(_vm._f("formatNumber")(country.revenue,2, true))+" ")]),_c('router-link',{attrs:{"id":`order-by-delivery-country-stat-${index}`,"to":{
              name: 'order.index',
              query: { delivery_country: country.code },
            }}},[(country.code === 'NL')?[_vm._v(" 🇳🇱 Nederland ")]:(country.code === 'BE')?[_vm._v(" 🇧🇪 België ")]:(country.code === 'DE')?[_vm._v(" 🇩🇪 Duitsland ")]:(country.code === 'FR')?[_vm._v(" 🇫🇷 Frankrijk ")]:[_vm._v(" 🏳️ Overige landen ")]],2),_c('b-popover',{attrs:{"target":`order-by-delivery-country-stat-${index}`,"triggers":"hover","placement":"right","boundary":"window","variant":"stat"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"stat-value"},[_vm._v(" € "+_vm._s(_vm._f("formatNumber")(country.revenue,2, true))+" "),_c('span',{staticClass:"stat-value-prev"},[_vm._v(" € "+_vm._s(_vm._f("formatNumber")(country.revenue_previous,2, true))+" ")])]),_c('span',{staticClass:"stat-icon ml-4 d-inline-block"},[_c('i',{staticClass:"far",class:{
                    'fa-arrow-trend-up text-success':
                      country.revenue > country.revenue_previous,
                    'fa-arrow-trend-down text-danger':
                      country.revenue < country.revenue_previous,
                  }})])])])],1)}):[_vm._v(" Er is geen omzet gevonden voor deze periode ")]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }