export const OrderChannel = {
  WOOCOMMERCE: 1,
  GADERO: 2,
  WOOCOMMERCE_FR: 1,
  WOOCOMMERCE_EU: 1,
  WOOCOMMERCE_BE: 1,
  WOOCOMMERCE_DK: 1,
  WOOCOMMERCE_ES: 1,
  WOOCOMMERCE_DE: 1,
};

export const OrderChannelString = {
  1: 'WooCommerce',
  2: 'Gadero',
  3: 'WooCommerce',
  4: 'WooCommerce',
  5: 'WooCommerce',
  6: 'WooCommerce',
  7: 'WooCommerce',
  8: 'WooCommerce',
};